import React from 'react'

function Footer() {
  return (
    <footer className="bg-gray-800">
      <div className="flex flex-col md:flex-row items-center md:justify-between py-6 max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-5xl space-y-6 md:space-y-0">
        <p className="text-gray-300">© 2019 - 2023 Powered by GET BONUS
</p>
      </div>
    </footer>
  )
}

export default Footer
